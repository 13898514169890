import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/lib/contentful-preview/ContentfulInspector.tsx");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/lib/utlils/clicktrackerwrapper.tsx");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/lib/utlils/pageviewlogging.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/article-snippet/text-toggle-comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/Banners/hero/slider-pod/heroSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/faq/faq-clientwrap.tsx");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/our-partners/ourpartnercomponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/searchBar/search-pod/searchbox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/slider/advicecourseslidercomponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/slider/discoverslidercomponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/slider/reviewslidercomponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/common-utilities/testimonials/testimonialvideocomponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/skeleton/partnerslidercomponentskeleton.tsx");
